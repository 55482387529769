import React from "react"
import Layout from "../components/layouts/investments_layout"
import Meta from "../components/meta"

import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

import { GiThreeFriends } from "react-icons/gi"
import { MdAllInclusive } from "react-icons/md"
import { FaTrophy } from "react-icons/fa"
import { GiLockedChest } from "react-icons/gi"

import { graphql } from "gatsby"


const Investments = ({ data }) => {
  return (
    <Layout
      logo="logo_svg_investments.svg"
    >
      <Meta
        title="threesixty Investments Ltd"
        description="threesixty Investments Ltd seeks to acquire trading businesses with sales volumes of £2m-£20m. Distressed opportunities, unloved subsidiaries, retirement sales." />
      <Container>
        <Row>
          <Col>
            <h1>threesixty Investments Ltd</h1>
            <p>
              threesixty Investments Ltd is an investment vehicle. Its aim is to acquire trading businesses, broadly with sales volumes within the range of £0.5m-£20m.
            </p>
            <p>
              Our breadth of experience means that we can look at unloved subsidiaries of larger groups through to retirement sales.
            </p>
          </Col>
        </Row>
      </Container>
      <Container className="mt-4">
        <Row>
          <Col>
            <h2>Business Ethos</h2>
            <p>
              Our ethos is long term commitment to create long term value for all
              parties concerned. We work with management teams and other
              stakeholders to ensure an optimisation of outcomes.
            </p>
            <p className="lead">
              We are <span className="emphasis text-investments-brand">entrepreneurial</span>
              . We make{" "}
              <span className="emphasis text-investments-brand">quick decisions</span>. We{" "}
              <span className="emphasis text-investments-brand">love what we do</span>.
            </p>
          </Col>
        </Row>
      </Container>
      <Container fluid className="bold-section mt-4 mb-4">
        <Container>
          <Row className="pt-4 pb-4">
            <Col>
              <h3>
                Our ability to access both debt and equity is unparalleled
              </h3>
              <h4>
                as is our desire to work with management teams and other
                stakeholders
              </h4>
            </Col>
          </Row>
        </Container>
      </Container>
      <Container className="mt-4">
        <h2>Methodology</h2>
        <Row className="pt-4 pb-4">
          <Col className="text-start">
            <p className="text-start"><GiThreeFriends size={20} /> We can invest our own funds together with those of Family offices within our network</p>
            <p className="text-start"><FaTrophy size={20} /> 2nd to none relationships with banks and funders ensures that debt where required is raised quickly and efficiently</p>
          </Col>
          <Col className="text-start">
            <p className="text-start"><GiLockedChest size={20} /> We draw on extensive financial and operational resources</p>
            <p className="text-start"><MdAllInclusive size={20} /> Sector agnostic</p>
          </Col>
        </Row>
      </Container>
    </Layout >
  )
}
export default Investments;

export const query = graphql`
  query {
    allMarkdownRemark(
      filter: {fields: {type: {eq: "investments" } } }
      sort: {fields: [frontmatter___date], order: DESC }
    ) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "DD MMMM, YYYY")
            thumbnail {
              childImageSharp {
                gatsbyImageData(width: 500, quality: 90)
              }
            }
            summary
            acquired
          }
          fields {
            slug
          }
          excerpt
        }
      }
    }
    imageOne: file(relativePath: {eq: "samuel-zeller-118195-unsplash.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 700, quality: 90)
      }
    }
  }
`